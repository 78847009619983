import styled from 'styled-components'
import React from 'react'
import { PageCopy } from '../global'
import ArrowRight from '../../svg/arrow-right'

const IntroCopy = ({ handleClick }) => {
  return (
    <>
      <PageCopy>
        <p>
          We believe in creating an awesome journey from the beginning to the
          all-important finish. Here are some considerations to help get us on
          the same page from the start:
        </p>
        <List>
          <li>
            The timeline to work with us ranges from 2 months up to 6 months
            when we're at capacity
          </li>
          <li>
            The best time to engage us is the design concepts stage so we can
            help with early cost visibility
          </li>
          <li>
            Our project values start from $1.5M with a minimum cost of $4,000
            p/sqm
          </li>
          <li>
            While our clients' experience ranges from first to second or third
            build, all intend to enjoy their homes for 10+ years
          </li>
          <li>
            Our clients are almost always busy professionals with families, they
            are seeking a fully managed, turn-key architectural build.
          </li>
        </List>
        <p>
          If this all sounds good to you, let’s talk about your project plans.
        </p>
      </PageCopy>
      <StyledButton onClick={handleClick}>
        <span>Get started</span>
        <ArrowRight />
      </StyledButton>
    </>
  )
}

export default IntroCopy

const List = styled.ul`
  li {
    list-style: disc;
  }
  padding: 0 0 0 1em;
  margin-bottom: 1.5rem;
`

const StyledButton = styled.button`
  font-weight: normal;
  height: auto;

  border-radius: 0;
  padding: 1rem;
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.tertiary};
  background-color: ${({ theme }) => theme.colors.primary};
  ${({ theme }) => theme.fontSizing(theme.font.buttonSizes)};

  svg {
    width: 1.5rem;
    margin-left: 2rem;
  }
`
