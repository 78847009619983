import React from 'react'
import { Box, Flex } from 'reflexbox/styled-components'
import { PageCopy, PageTitle } from '../global'

const ThankYouMessage = () => {
  return (
    <>
      <PageTitle>Thanks</PageTitle>
      <PageCopy>
        <p>
          A copy of your project will be emailed shortly and we will be in touch
          soon.
        </p>
      </PageCopy>
    </>
  )
}

export default ThankYouMessage
