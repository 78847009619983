import React from 'react'
import styled from 'styled-components'

const Hide = ({ hide, children }) => {
  return <HideWrapper hide={hide}>{children}</HideWrapper>
}

const HideWrapper = styled.div`
  display: ${({ hide }) => (hide ? 'none' : 'block')};
`

export default Hide
