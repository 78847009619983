import React, { useEffect, useState } from 'react'
import { Box, Flex } from 'reflexbox/styled-components'
import {
  PageWrap,
  PageHead,
  PageRow,
  PageCopy,
  PageTitle,
} from '../components/global'
import SEO from '../components/seo'
import Hide from '../components/Hide'

import ThankYouMessage from '../components/contact/ThankYouMessage'
import IntroCopy from '../components/contact/IntroCopy'

import styled from '@emotion/styled'

export default () => {
  const [renderDiv, setRenderDiv] = useState(false)
  const [hideThankYouMessage, setHideThankYouMessage] = useState(true)
  const [hideGoodForm, setHideGoodForm] = useState(true)
  const [hideIntro, setHideIntro] = useState(false)

  useEffect(() => {
    setRenderDiv(true)
  }, [])

  useEffect(() => {
    if (document.querySelector('#goodform-1')) {
      const target = document.querySelector('#goodform-1')
      target.addEventListener('submitted', () => {
        setHideThankYouMessage(false)
        setHideGoodForm(true)
      })
      target.addEventListener('backToIntro', () => {
        setHideIntro(false)
        setHideGoodForm(true)
      })
    }
  }, [renderDiv])

  const handleClick = () => {
    setHideIntro(true)
    setHideGoodForm(false)
    setTimeout(() => {
      document.getElementById('goodform-1').scrollIntoView()
    }, 10)
  }

  return (
    <PageWrap>
      <SEO title="Contact" />
      <PageRow>
        <PageHead>
          New Project
          <br />
          <span>Enquiries</span>
        </PageHead>
        <Flex>
          <FormWrapper
            ml={[0, `${(2 / 10) * 100}%`]}
            width={[1, 4 / 8]}
            px={[2, 3]}
            pt={[3, 4]}
            pb={[0, 0]}
          >
            <Hide hide={hideIntro}>
              <IntroCopy handleClick={handleClick} />
            </Hide>
            <Hide hide={hideGoodForm}>
              <div id="goodform-1"></div>
            </Hide>
            <Hide hide={hideThankYouMessage}>
              <ThankYouMessage />
            </Hide>
          </FormWrapper>
        </Flex>
      </PageRow>
    </PageWrap>
  )
}

const FormWrapper = styled(Box)`
  /* min-height: 70vh; */
`
